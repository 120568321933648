<template>
  <div class="wrapper">
    <div class="banner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <img src="../../assets/img/partner/swp1.png" class="swiper-slide" />
          <img src="../../assets/img/partner/swp1.png" class="swiper-slide" />
        </div>
        <!-- 左右箭头(这是自定义写的) -->
        <!-- <img src="../../assets/whalbum/arrow.png" class="swiper-button-next" />
        <img src="../../assets/whalbum/arrow.png" class="swiper-button-prev" /> -->
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <!-- <div class="container">
      <div class="box">
        <div class="box_title">生态网</div>
        <div class="content">中小微企业</div>
      </div>
    </div> -->
    <div class="container container_bg">
      <div class="box">
        <div class="title">政府合作伙伴</div>
        <div class="box_img">
          <img src="../../assets/img/partner/partner.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 企业合作伙伴 -->
    <div class="container">
      <div class="box">
        <div class="title">企业合作伙伴</div>
        <div class="icon_list">
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/1.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/2.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/3.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/4.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/5.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/6.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/7.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/8.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/9.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/10.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/11.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/12.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/13.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/14.png" alt="" />
          </div>
          <div class="icon_item">
            <img src="../../assets/img/partner/enterprise_logo/15.png" alt="" />
          </div>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <!-- 银行合作伙伴 -->
    <div class="container container_bg">
      <div class="box">
        <div class="title">银行合作伙伴</div>
        <div class="icon_list">
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/1.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/2.png" alt="" />
            </div>
            <!-- <span>中国银行山西某某分行</span> -->
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/3.png" alt="" />
            </div>
            <!-- <span>中国银行山西某某分行</span> -->
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/4.png" alt="" />
            </div>
            <!-- <span>中国银行山西某某分行</span> -->
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/5.png" alt="" />
            </div>
            <!-- <span>中国银行山西某某分行</span> -->
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/6.png" alt="" />
            </div>
            <!-- <span>中国银行山西某某分行</span> -->
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/7.png" alt="" />
            </div>
            <!-- <span>中国银行山西某某分行</span> -->
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/8.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/9.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/10.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/11.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/12.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/13.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/14.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/15.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/16.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/17.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/18.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/19.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/20.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/21.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/22.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/23.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/24.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/25.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/26.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/27.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/28.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/29.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/30.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/31.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/32.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/33.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/34.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/35.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/36.png" alt="" />
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/img/partner/bank_logo/37.png" alt="" />
            </div>
          </div>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import "swiper/dist/css/swiper.css";
// import { swiper, swiperSlide } from "vue-awesome-swiper";
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    // swiper,
    // swiperSlide,
  },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    initSwiper() {
      new Swiper(".swiper-container1", {
        effect: "coverflow",
        slidesPerView: 3,
        loop: true,
        // initialSlide: 2,
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        coverflowEffect: {
          rotate: 30,
          stretch: 10,
          depth: 60,
          modifier: 2,
          slideShadows: false,
        },
        observeParents: false, //注意这里！！
        observer: true, //也要注意这里！！
      });
    },
  },
  created() {},
  mounted() {
    this.initSwiper();

    let that = this; //如果下面想要使用变量，请定义
    that.mySwiper = new Swiper(".swiper-container", {
      autoplay: true, //是否自动滚动
      loop: true, //是否可以循环
      initialSlide: 0, //初始化第几页
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }, //使用左右按钮
      // on: {
      //   transitionEnd() {
      //     //点击过渡完触发事件
      //     that.activeIndex = that.mySwiper.activeIndex; //activeIndex轮播下标
      //   },
      // }, //事件
    });
    // this.mySwiper.slideTo(index, 500, false); //点击跳转到指定的图片中
  },
};
</script>
<style scoped>
.wrapper {
  padding-bottom: 40px;
}

.banner {
  width: 100%;
  height: 420px;
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
}

.swiper-wrapper img {
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
  padding: 60px;
}

.container_bg1 {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.box_title {
  font-size: 20px;
  font-weight: 600;
}

.title {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.content {
  font-size: 24px;
  font-weight: 600;
}

.box_img {
  width: 100%;
  padding: 20px;
  margin-top: 20px;
}

.box_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.icon_list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.icon_list span {
  width: 19%;
}

.icon_item {
  width: 19%;
  height: 85px;
  border-radius: 10px;
  margin-bottom: 15px;
  background: #f5f5f5;
  overflow: hidden;
  padding: 20px 0;
}

.icon_item img {
  display: block;
  /* width: 80%; */
  /* height: 100%; */
  margin: 0 auto;
}

.icon {
  width: 170px;
  height: 40px;
}

.icon_item span {
  display: block;
  width: 100%;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.container_bg .icon_item {
  background: #fff;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.swiper_box {
  margin-top: 40px;
}
.swiper-container1 {
  padding: 10px;
  overflow: hidden;
}

.slide_box {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
}

.slidecontent .name {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.slidecontent span {
  display: block;
  font-size: 12px;
  margin: 10px;
}

.left_list {
  padding: 20px;
  width: 400px;
  background: #fff;
}

.left_list ul li {
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 20px;
}

.infoIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}

.infoIcon img {
  display: block;
  width: 100%;
  height: 100%;
}

.info {
  display: flex;
  flex-direction: column;
}

.info span {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}
.info p {
  margin-top: 10px;
  font-size: 12px;
  color: #666666;
}

.right_map {
  flex: 1;
  height: 100%;
}

.right_map img {
  display: block;
  width: 100%;
  height: 100%;
}

.flex_box {
  display: flex;
}

.flex_box_w {
  margin-top: 40px;
  height: 320px;
  border-radius: 8px;
  overflow: hidden;
}
</style>
